/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */
const React = require("react")

// You can delete this file if you're not using it
// import 'bootstrap/dist/css/bootstrap.min.css';
const Bootstrap = require('bootstrap/dist/css/bootstrap.min.css')

// Monitor this, trying to get gatsby to fire onload or ready events.
// export const onClientEntry = () => {
//     console.log("onClientEntry: browser")
//     window.onload = () => {
//         console.log("onClientEntry: Window Loaded")
//     }
// }

// or commonjs
// This fires but not on every page load
// exports.onClientEntry = () => {
//     console.log("onClientEntry: browser")
//     window.onload = () => {
//         console.log("onClientEntry: Window Loaded")
//         // try {
//         //     m();
//         // } catch(err) {
//         //     console.log("onClientEntry: Error - " + err);
//         // }
//     }
// }

// TODO: Keep an eye on this, it seems that it works
exports.onRouteUpdate = ({ location, prevLocation }) => {
    // console.log("onRouteUpdate: new pathname", location.pathname)
    // console.log("onRouteUpdate: old pathname", prevLocation ? prevLocation.pathname : null)
    // This looks to solve my problem of loading the when the pages are hydrated.
    // We can just call it from here, and we should be
    // Stubs out the chdr info.

    try {
        if (typeof m() === "function") {
            // safe to use the function
            m();
        }
    } catch (err) {
        console.log('Err: ' + err)
    }
}

